

.navbar {
    padding : 0px 40px;
}


.logo {
    height: 8vh;
}

.navbar-toggler {
    color: black;
}
.navbar-toggler-icon {
    color: black;
}

.nav-link {
    font-family: "inter";
    font-weight: 600;
    color: black;
    position: relative;
    padding: 0px;

}

@media (max-width :991px) {
    .nav-link {
        text-align: center;
        font-size: 1.5rem;
        padding: 10px;
    }
}


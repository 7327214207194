



#HomeProject {
    
    padding-top: 50px;

}

#HomeProjectHeading {
    font-family: 'montserrat';
    font-size: 4rem;
    text-align: center;
    padding: 40px 0px;
    color: black;
    
}

.HomeProjectsCardsContainer {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 40px;
    place-items: center;
    padding: 30px;


}

.card {
    background-color: #262626;
    width: 90%;
    border: 0px;
}

.card-body {
    display: flex;
    justify-content: space-between;
}
.card-img-top {
    height: 18rem;
}

.card-title {
    font-size: 1.5rem;
    font-family: "montserrat";
    color: white;
    font-weight: 400;

}

.card-city {
    color: rgba(255,255,255,0.6);
    font-weight: 400;
    font-size: 1.1rem;
}

.cardBtn {

    padding: 0.5 0.7rem;
    border-radius: 100px 100px 100px 100px;

    background-color: white;
    color: black;
    font-family: 'inter';
    font-weight: 500;

}

.cardBtn:hover {
    background-color: transparent;
    border : 2px white ;
    color: white;
}

.ArrowRight {
    height: 30px;
    width: 30px;
    padding: 2px;
}

.ArrowRight:hover {
    border : 2px white !important;
}




@media (max-width : 991px) {
    
    #HomeProjectHeading {
        font-size: 3rem;
        
    }

    .HomeProjectsCardsContainer {
        grid-template-columns: repeat(2,1fr);
    }
}

@media (max-width : 480px) {

    #HomeProject {
        padding: 10px;
    }

    #HomeProjectHeading {
        font-size: 2.5rem;
        padding: 20px;
    }

    .HomeProjectsCardsContainer  {
        grid-template-columns: repeat(1,1fr);
    }


    .card {
        width: 100%;
    }
}


/* importing Inter font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

/* adding Montserrat */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700;800&display=swap');

/* adding lobsters */
@import url("https://fonts.googleapis.com/css2?family=Lobster&family=Lobster+Two:wght@700&family=Yeseva+One&display=swap");

/* adding raleway  */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600;700&display=swap');




* {
    font-family: "inter";
}

body {
    background-color: white;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  


.projectHeading {
    font-size: 64px;
    font-family: 'montserrat';
    font-weight: 700;
    padding-left : 40px;
    padding-top : 40px;
    color: white;
}

.projectHeading_description {
    font-weight: 500;
    color: rgba(255,255,255,0.8);
    padding-left: 40px;
}
.project_header_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top : 200px;
    padding-bottom: 200px;
    background-position: center left;
    background-image: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)) , url('./resources/images/project_hero2.jpg');
}





/* 
.ProjectHeader {
    display: flex;
    padding: 20px;
    height: 550px;


} */

.details {
    display: flex;
    flex-direction: row;
    padding: 40px;
    justify-content: center;
}

.ProjectHeading {
    font-family:  "Montserrat";
    font-size: 3rem;
}

.ProjectCity {
    font-weight: 500;
    font-size: 1.2rem;
}

.viewGallery {
    padding: 1rem 2rem;
    border-radius: 100px;
    
}

.details p {
    padding-left: 150px;
}

/* 
.slider {
    width: 65%;
    height: 100%;
} */

.carousel-item {
    height: 550px;
}

@media (max-width : 1280px) {   
        .ProjectHeading {
            font-size : 3rem;
        }
        .ProjectCity {
            font-size: 1.5rem;
        }
        .details {
            height: auto;
        }

}

@media (max-width : 991px) {
    .ProjectHeader {
        flex-direction: column-reverse;
        height: auto;
    }

    .slider {
        width: 100%;
    }

    .details {
        width: 100%;
    }
}


@media (max-width : 480px ) {
    .ProjectHeader {
        padding: 5px;
    }

    .slider {
        height: 50% ;
    }

    .carousel-item {
        height: 50%;
    }

    .ProjectHeading {
        font-size: 2.5rem;
    }

    .ProjectCity {
        font-size: 1.5rem;
    }


}
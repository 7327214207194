.GalleryHeading {
  font-family: "montserrat";
  font-size: 3rem;
  font-weight: 700;
  text-align: left;
  padding: 30px;
  padding-left : 4.7rem;

  color: black;
}

.Gallery {
  width: 100vw;
  overflow: hidden;

  padding-left: 5rem;
  padding-right: 5rem;
}

.GalleryImage {
  padding-top: 20px;
  padding-bottom: 10px;

  width: 95%;
  height: auto;
}

@media (max-width: 991px) {
  .GalleryHeading {
    font-size: 2.5rem;
  }
}

@media (max-width: 576px) {
  .Gallery {
    padding-left: 20px;
    padding-right: 20px;
  }

  .GalleryImage {
    padding: -10px;
  }

  .GalleryHeading {
    font-size: 1.5rem;
    padding-top: 50px;
    padding-bottom: 10px;
  }
}


/* other code */

/* src/ImageGallery.css */
.image-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  padding: 30px;
}

.image-card {
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s, box-shadow 0.2s;
  cursor: pointer;
  height: 100%;
}

.image-card img { 
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.2s;
}

.image-card:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

/* Add more styling as needed */

/* before AFter  */

.before_after {
  display: flex;
  justify-content:space-evenly;
  align-items: center;

  border: 2px solid rgb(173, 173, 173);
  margin: 0px 100px;
  border-radius: 20px;


}

.before_after div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}



.before_after button {
  margin : 20px 50px;
  padding : 10px 20px;
  border-radius: 0px;
  border: 0px ;
  background-color: transparent;

  color: black;
  font-weight: 500;
  font-family: 'inter';

  font-size: 1.5rem;

}

.before_after div:hover {
  transition: 0.2s;
  background-color: white;
  border: 3px solid black;
  border-radius: 20px;

}

.before_after div:hover button {
  color: black;
}


.before_after_active {
  border-radius: 0px;
  border: 0px ;
  background-color: black;
  color: white !important;

  border-radius: 20px;
}

.before_after_active button {
  transition: 0.2s;
  color: white;
}

.ArrowRightGallery {
  height: 30px;
  width: 30px;
  padding: 2px;
  color: white;
  background-color: white;
  border-radius: 100px;
  border: 0px;
  margin-left: 10px;
}
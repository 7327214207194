



#FilterHeading {
    font-size: 1.5rem;
    font-weight: 500;
    text-align: left;
    padding-top: 50px;
    padding-left: 40px;
}

.FilterContainer {

    margin-top: 50px;

    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 15%;
    
    overflow:auto;
    overscroll-behavior-inline: contain;
    margin-bottom: 70px;


    
}

/* Container CSs end ========================================================== */


/* Single Button cSS Applied */

.FilterRound {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-width: 120px;
    /* height: 220px;
    width: 180px; */
    border-radius: 100px;
    margin-right: 30px;
    margin-left: 30px;
    
}

.FilterRound img{
    height: 150px;
    width: 150px;
    border-radius: 10000px;
}

.FilterRound h6 {
    font-size: 1.2rem;
    margin-top: 10px;
}

@media (max-width : 1280px) {

    .FilterRound {
        max-width: 100px;
    }

    .FilterRound img {
        width: 100px;
        height: 100px;
    }
} 


@media (max-width : 991px) {

    #FilterHeading {
        font-size: 2.5rem;
    }

    .FilterContainer {
        grid-auto-columns: 22%;
    }
}

@media (max-width : 820px) {
    .FilterContainer {
        grid-auto-columns: 23%;
    }
}

@media (max-width:768px) {
    .FilterContainer {
        grid-auto-columns: 24%;
    }
}

@media (max-width : 480px) {

    #FilterHeading {
        font-size: 1.9rem;
        padding-top: 10px;
    }

    .FilterContainer {
        grid-auto-columns: 28%;

        margin-top: 20px;
        margin-bottom: 20px;
    }

    .FilterRound {
        min-width: 100px;
        margin-right: 25px;
    }
    .FilterRound {
        max-width: 95px;
    }
    .FilterRound img {
        width: 95px;
        height: 95px;
    }

    .FilterRound h6 {
        font-size: 1rem;
        margin-top: 10px;
    }
}

@media (max-width : 390px) {
    .FilterContainer {
        grid-auto-columns: 25%;
    }
    .FilterRound {
        max-width: 90px;
        width: 90px;
    }
    .FilterContainer img {
        max-width: 90px;
        height: 90px;
    }

}

@media (max-width : 360px) {
    .FilterContainer {
        grid-auto-columns: 32%;
    }
    .FilterRound {
        max-width: 90px;
    }

    .FilterContainer img {
        max-width: 90px;
    }
}


.Main {
    padding-top: 50px;
    padding-bottom: 0px;
    text-align: left;
    background-color: rgb(248, 245, 245);
    display: flex;
    flex-direction: column;
}

.FaqSection {
    padding: 50px;

}

.faqh2 {
    font-family: "montserrat";
    text-align: center;
    color: #262626;
    font-size: 4rem;
    font-weight: 700;

}




@media (max-width: 480px) {

    .Main {
        flex-direction: column;
    }

    .faqh2 {
        transform: rotate(0deg);
        position: static;
        font-size: 3rem;

    }

    .FaqSection {
        padding: 0px;
    }
}